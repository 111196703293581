<template>
  <div class="masonry">
    <vue-masonry-wall v-if="items.length" :items="items" :options="{width: 300, padding: 12}">
      <template v-slot:default="{item}">
          <div class="teaser item item-pointer" v-if="item.url" v-on:click="gotourl(item.url)">
            <img :src="item.image" :class="['card-img',{'product-image':item.gtin}]" v-if="item.image"/>
            <div class="item-content">
              <b-badge v-if="item.label" variant="primary" class="item-content-badge">{{item.label}}</b-badge>
              <h3>{{item.title}}</h3>
              <div v-html="item.description"></div>
              <b-form-row v-if="item.url" class="align-items-end">
                <b-col>
                  <a class="teaser-arrow" ref="link" :href="item.url">
                    <b-icon icon="arrow-right" scale="2.5"/>
                  </a>
                </b-col>
              </b-form-row>
            </div>
          </div>
        <div class="item" v-else>
            <img :src="item.image" v-if="item.image" />
            <div class="item-content">
              <b-badge v-if="item.label" variant="primary" class="item-content-badge">{{item.label}}</b-badge>
              <h3>{{item.title}}</h3>
              <div v-html="item.description"></div>
            </div>
        </div>
      </template>
    </vue-masonry-wall>
    <div v-if="!items.length">
      {{$T('noresult')}}
    </div>
  </div>
</template>

<script>
import VueMasonryWall from 'vue-masonry-wall'

export default {
  name: 'MasonryGrid',
  components: { VueMasonryWall },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    gotourl (url) {
      window.location.href = url
    }
  }
}
</script>

<style lang="scss">
$teaser-img-transition-in: transform ease-out 200ms !default;
$teaser-img-transition-out: transform ease-out 200ms !default;

$teaser-arrow-animation: h-bounce 400ms ease-out 1;

 .masonry{

  .item-pointer {
    cursor:pointer;
  }

  .item{
    border-top-left-radius: 2.05625rem;
    border-bottom-right-radius: 2.05625rem;
    background-color: $white;
    overflow: hidden;

    a{
      text-decoration:none;
    }

    img{
      margin:0;
      width:100%;
      &.product-image{
        margin: 2rem 2rem 1rem;
        width: calc(100% - (2rem * 2));
      }
    }

    &-content{
      padding:20px;
      position:relative;
      word-break:break-word;

      &-badge{
        margin-bottom:20px;
      }
    }

    &:hover {
    .card-img {
      transform: scale(1.05);
      transition: $teaser-img-transition-in;
    }

    .teaser-arrow {
      animation: $teaser-arrow-animation;
    }
  }
  }

  .teaser-arrow {
    display: block;
    text-align: right;

    .bi {
      width: 2em;
    }
  }
 }
</style>
